import React, { CSSProperties, FunctionComponent } from 'react';
import { AreaInput, Label } from './TextArea.style';
import ValidationError from './ValidationError';

interface Props {
  containerStyle?: CSSProperties;
  inputStyle?: CSSProperties;
  label?: string;
  name: string;
  value: string;
  onValueChange: (value: string) => void;
  error?: false | string;
  rows: number;
  placeholder?: string;
  testID?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  autoHeight?: boolean;
  borderColour?: string;
}

const TextArea: FunctionComponent<Props> = (props) => {
  const resizeInput = (event: any) => {
    if (props.autoHeight) {
      event.target.style.height = '5px';
      event.target.style.height = `${event.target.scrollHeight}px`;
    }
  };

  return (
    <div style={props.containerStyle}>
      {props.label && <Label htmlFor={props.name}>{props.label}</Label>}

      <AreaInput
        name={props.name}
        value={props.value}
        onChange={(e) => props.onValueChange(e.target.value)}
        rows={props.rows}
        placeholder={props.placeholder}
        data-testid={props.testID}
        error={props.error}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        onInput={resizeInput}
        style={props.inputStyle}
        borderColour={props.borderColour}
      />

      <ValidationError error={props.error} />
    </div>
  );
};

export default TextArea;
