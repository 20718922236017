import styled from 'styled-components';

export const Label = styled.label`
  display: block;
  color: ${(props) => props.theme.textDark};
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
`;

export const AreaInput = styled.textarea<{ error?: false | string; borderColour?: string }>`
  display: block;
  background: ${(props) => props.theme.background};
  color: ${(props) => (props.disabled ? props.theme.backgroundMid : props.theme.textDark)};
  font-size: 16px;
  padding: 15px;
  box-shadow: inset 0 0 0
    ${(props) => (props.error ? `2px ${props.theme.primary}` : `1px ${props.borderColour || props.theme.keyline}`)};
  border: none;
  min-width: 0;
  width: 100%;
  -webkit-appearance: none;
  border-radius: 0;
  resize: none;
  font-family: inherit;

  &::placeholder {
    color: ${(props) => props.theme.backgroundMid};
  }
`;
